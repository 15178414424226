import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styled from 'styled-components';
import { LineChart, Line, CartesianGrid, XAxis, YAxis, Tooltip } from 'recharts';
import { parseStringPromise } from 'xml2js';


// Interface for server data
interface ServerData {
  IP: string;
  Ping: string;
  TimeDifferenceInSeconds: number;
  lTime: string;
  pCPU: string;
  pMEM: string;
  pWIN: string;
  pESPACO: string;
  Link1: string;
  Link: string;
  Descr: string;
}

// Interface for server history data
interface ServerHistoryData {
  Ping: string;
  pCPU: string;
  pMEM: string;
  pESPACO: string;
}

// Main App component
const App: React.FC = () => {
  const [data, setData] = useState<ServerData[]>([]);
  const [selectedServer, setSelectedServer] = useState<ServerData | null>(null);
  const [historyData, setHistoryData] = useState<ServerHistoryData[]>([]);

  const [totalServers, setTotalServers] = useState(0);
  const [internalServersCount, setInternalServersCount] = useState(0);
  const [customerServersCount, setCustomerServersCount] = useState(0);
  const [newServersCount, setNewServersCount] = useState(0);
  const [alertServersCount, setAlertServersCount] = useState(0);
  const [offlineServersCount, setOfflineServersCount] = useState(0);

  // Fetch server data every 2 seconds from the XML-based API
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://monitor.ayrton.ai/api/servers.asp', {
          headers: {
            'Content-Type': 'application/xml',
          },
          responseType: 'document',
        });

        const xml = response.data;
        const jsonData = await parseXML(xml);
        //console.log(jsonData.servers.server);        
        setData(jsonData.servers.server);

        // Set statistics based on the server data
        setTotalServers(jsonData.servers.server.length);
        setInternalServersCount(jsonData.servers.server.filter((server: ServerData) => !server.Link[0].startsWith('null')).length);
        setCustomerServersCount(jsonData.servers.server.filter((server: ServerData) => server.Link[0].startsWith('null')).length);
        setNewServersCount(jsonData.servers.server.filter((server: ServerData) => !server.IP[0].includes('.')).length - 1);
        setAlertServersCount(0); // Reset alert count
        setOfflineServersCount(jsonData.servers.server.filter((server: ServerData) => server.TimeDifferenceInSeconds > 60).length);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    const interval = setInterval(() => {
      fetchData();
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  // Fetch server history when a server is selected
  useEffect(() => {
    if (selectedServer) {
      const fetchHistory = async () => {
        try {
          const response = await axios.get(`https://monitor.ayrton.ai/api/history.asp?ip=${selectedServer.IP}`, {
            headers: {
              'Content-Type': 'application/xml',
            },
            responseType: 'document',
          });

          const xml = response.data;
          const jsonData = await parseXML(xml);

          if (jsonData.history && jsonData.history.record) {
            setHistoryData(jsonData.history.record);
          } else {
            setHistoryData([]); // Handle no history data
          }
        } catch (error) {
          console.error('Error fetching server history:', error);
        }
      };

      fetchHistory();
    }
  }, [selectedServer]);  

  // Parse XML response to JS object
  const parseXML = async (xml: Document) => {
    const xmlString = new XMLSerializer().serializeToString(xml);
    const result = await parseStringPromise(xmlString);
    return result;
  };

  // Separate servers by category
  const clients = data.filter(server => {
    return server.Link[0].startsWith('null'); 
  });
  const internalServers = data.filter(server => {
    return !server.Link[0].startsWith('null'); 
  });

  // Get color based on usage
  const getUsageColor = (usage: string | number) => {
    if (typeof usage === 'string') {
      try {
        usage = +usage.replace(/\D/g, ''); // Remove non-numeric characters
      } catch (error) {
        return 'success'; // Return default color for zero if usage is not a number
      }
    }
    if (usage < 50) return 'success';
    if (usage < 85) return 'primary';
    if (usage < 95) return 'warning';
    return 'danger';    
  };

  // Use an effect to track and update alert count based on usage
  useEffect(() => {
    data.forEach(server => {
      const cpuUsage = +server.pCPU[0].split('|')[1]; // Get CPU usage
      const memUsage = 100 * (+server.pMEM[0].split('|')[1] - +server.pMEM[0].split('|')[0]) / +server.pMEM[0].split('|')[1]; // Get memory usage
      const diskUsage = Math.round(((Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[1])) - Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[0]))) / Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[1]))) * 100); // Get disk usage
      if (cpuUsage >= 95 || memUsage >= 95 || diskUsage >= 95) {
        setAlertServersCount(prevCount => prevCount + 1);
      }
    });
  }, [data]); // Track changes in data

  const getOnlyNumbers = (input: string): string => {
    return input.replace(/\D/g, ''); // Remove all non-numeric characters
  };

  return (
    <div className="container-fluid">
      {/* First Row of Statistics */}      
      <div className="row">
          <div className="col-12">&nbsp;</div>
      </div>                        
      <div className="row">
        <div className="col-xl-2 col-lg-2">
          <div className="card tilebox-one">
            <div className="card-body">
              <i className='mdi mdi-server float-end'></i>
              <h6 className="text-uppercase mt-0">Total Servers</h6>
              <h2 className="my-2 pt-1" id="total-servers-count">{totalServers}</h2>
            </div>
          </div>
        </div>

        <div className="col-xl-2 col-lg-2">
          <div className="card tilebox-one">
            <div className="card-body">
              <i className='mdi mdi-server-network float-end'></i>
              <h6 className="text-uppercase mt-0">Internal Servers</h6>
              <h2 className="my-2 pt-1" id="internal-servers-count">{internalServersCount}</h2>
            </div>
          </div>
        </div>

        <div className="col-xl-2 col-lg-2">
          <div className="card tilebox-one">
            <div className="card-body">
              <i className='mdi mdi-server-security float-end'></i>
              <h6 className="text-uppercase mt-0 text-info">Customer Servers</h6>
              <h2 className="my-2 pt-1" id="customer-servers-count">{customerServersCount}</h2>
            </div>
          </div>
        </div>

        <div className="col-xl-2 col-lg-2">
          <div className="card tilebox-one">
            <div className="card-body">
              <i className='mdi mdi-server-plus bg-success float-end'></i>
              <h6 className="text-uppercase mt-0">New Servers</h6>
              <h2 className="my-2 pt-1" id="new-servers-count">{newServersCount}</h2>
            </div>
          </div>
        </div>

        <div className="col-xl-2 col-lg-2">
          <div className="card tilebox-one">
            <div className="card-body">
              <i className='mdi mdi-alert-outline bg-warning float-end'></i>
              <h6 className="text-uppercase mt-0">ALERT</h6>
              <h2 className="my-2 pt-1" id="alert-count">{alertServersCount}</h2>
            </div>
          </div>
        </div>

        <div className="col-xl-2 col-lg-2">
          <div className="card tilebox-one">
            <div className="card-body">
              <i className='mdi mdi-close-thick bg-danger float-end'></i>
              <h6 className="text-uppercase mt-0">OFF-LINE</h6>
              <h2 className="my-2 pt-1" id="offline-count">{offlineServersCount}</h2>
            </div>
          </div>
        </div>
      </div>

      {/* Second Row - Server Cards */}
      <div className="row">
        {data.map(server => (
          <div className="col-xl-3 col-lg-3" key={server.IP[0]}>
            <div className="card">
              <div className={server.IP[0].toUpperCase() !== '@CONTROLE' ? 'card-body' : 'card-body bg-warning'} >
                <h4 className="header-title mt-1 mb-3">IP: {server.IP[0]}
                  <span className="float-end">Ping: {server.Ping[0].replace('/' + new Date().getFullYear(),'')}</span><br />
                  <span className="text-info float-end">lTime: {server.lTime[0].replace('/' + new Date().getFullYear(),'')}</span><br />
                </h4>

                <div className="table-responsive">
                  <table className="table table-sm table-centered mb-0 font-14">
                    <thead className={server.Link[0].replace('null', '') === '' ? 'table-info' : 'table-light'}>
                      <tr>
                        <th style={{ width: '25%', textAlign: 'center', fontSize: '12px' }}>{server.Descr[0].replace('null', '')}  </th>
                        <th colSpan={2} style={{ width: '50%', textAlign: 'center' }}>{server.Link1[0].replace('null', '')}</th>
                        <th style={{ width: '25%', textAlign: 'center', fontSize: '10px' }}>{server.Link[0].replace('null', '').replace(/\./g, '. ')}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>CPU Cores</td>
                        <td style={{ textAlign: 'center' }}>{server.pCPU[0].split('|')[0]}</td>                        
                        <td className={`font-20 text-${getUsageColor(+server.pCPU[0].split('|')[1])} float-end`}>{server.pCPU[0].split('|')[1]}%</td>
                        <td>
                          <div className="progress" style={{ height: '6px' }}>
                            <div className={`progress-bar bg-${getUsageColor(+server.pCPU[0].split('|')[1])}`} role="progressbar" style={{ width: `${server.pCPU[0].split('|')[1]}%`, height: '20px' }} aria-valuenow={Number(server.pCPU[0].split('|')[1])} aria-valuemin={0} aria-valuemax={100}></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>MEM GB</td>
                        <td style={{ textAlign: 'center' }}>{(+server.pMEM[0].split('|')[1]-+server.pMEM[0].split('|')[0]) / 10}/{+server.pMEM[0].split('|')[1] / 10}</td>
                        <td className={`font-20 text-${getUsageColor(100*(+server.pMEM[0].split('|')[1]-+server.pMEM[0].split('|')[0])/+server.pMEM[0].split('|')[1])} float-end`}>{(100*(+server.pMEM[0].split('|')[1]-+server.pMEM[0].split('|')[0])/+server.pMEM[0].split('|')[1]).toFixed(0)}%</td>
                        <td>
                          <div className="progress" style={{ height: '6px' }}>
                            <div className={`progress-bar bg-${getUsageColor(100*(+server.pMEM[0].split('|')[1]-+server.pMEM[0].split('|')[0])/+server.pMEM[0].split('|')[1])}`} role="progressbar" style={{ width: `${server.pMEM[0].split('|')[1]}%`, height: '20px' }} aria-valuenow={Number(server.pMEM[0].split('|')[1])} aria-valuemin={0} aria-valuemax={100}></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>Disk GB</td>
                        <td style={{ textAlign: 'center' }}>{Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[0]) / 10).toFixed(1)}&nbsp;of&nbsp;{Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[1]) / 10).toFixed(1)}</td>
                        <td className={`font-20 text-${getUsageColor(Math.round(((Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[1])) - Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[0]))) / Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[1]))) * 100))} float-end`}>{Math.round(((Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[1])) - Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[0]))) / Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[1]))) * 100)}%</td>
                        <td>
                          <div className="progress" style={{ height: '6px' }}>
                            <div className={`progress-bar bg-${getUsageColor(Math.round(((Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[1])) - Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[0]))) / Number(parseFloat(server.pESPACO[0].split(':')[1].split('|')[1]))) * 100))}`} role="progressbar" style={{ width: `${Math.round(((Number(server.pESPACO[0].split(':')[1].split('|')[1]) - Number(server.pESPACO[0].split(':')[1].split('|')[0])) / Number(server.pESPACO[0].split(':')[1].split('|')[1])) * 100)}%`, height: '20px' }} aria-valuenow={Math.round(((Number(server.pESPACO[0].split(':')[1].split('|')[1]) - Number(server.pESPACO[0].split(':')[1].split('|')[0])) / Number(server.pESPACO[0].split(':')[1].split('|')[1])) * 100)} aria-valuemin={0} aria-valuemax={100}></div>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td>O.S.</td>
                        <td style={{ textAlign: 'center' }}><i className={server.pWIN[0].includes('Linux') ? 'mdi mdi-linux font-22' : 'mdi mdi-microsoft-windows font-22'}></i></td>
                        <td className={`font-20 text-${getUsageColor(10.1 * (+server.pWIN[0].split(' ')[3] - 2003))} secondary float-end`}>{getOnlyNumbers(String(server.pWIN[0].split(' ')[3]))}</td>
                        <td>
                          <div className="progress" style={{ height: '3px' }}>
                            <div className={`progress-bar bg-${getUsageColor(10.1 * (+server.pWIN[0].split(' ')[3] - 2003))}`} role="progressbar" style={{ width: '0%', height: '20px' }} aria-valuenow={0} aria-valuemin={0} aria-valuemax={100}></div>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default App;
